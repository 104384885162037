.root {
  width: 80%;
  margin: 0 auto;
}

.contact {
  cursor: pointer;
}

.header {
  justify-content: center;
  text-align: center;
}